import React, { useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import './CalendarSmall.scss';
import { ArrowRightBlueIcon } from '../../assets/icons';
import { BookingSettings, Desk, DeskStatus, RecurringBookingSettings } from "../../types";
import { getDeskStatus } from "../../utils/deskCardUtils";

interface CalendarSmallProps {
    onChangeDate: (date: Date) => void,
    bookingOptions?: BookingSettings,
    recurringBookingOptions?: RecurringBookingSettings,
    date?: Date,
    desk?: Desk;
}

/// Small calendar component.
const CalendarSmall: React.FC<CalendarSmallProps> = ({ onChangeDate, bookingOptions, recurringBookingOptions, desk, date }) => {
    const [currentDisplayDate, setCurrentDisplayDate] = useState<Date>(date ? date : new Date());

    const goToPreviousMonth = () => {
        const newDate = new Date(currentDisplayDate);
        newDate.setDate(1);
        newDate.setMonth(newDate.getMonth() - 1);
        setCurrentDisplayDate(newDate);
        onChangeDate(newDate);
    };

    const goToNextMonth = () => {
        const newDate = new Date(currentDisplayDate);
        newDate.setDate(1);
        newDate.setMonth(currentDisplayDate.getMonth() + 1);
        setCurrentDisplayDate(newDate);
        onChangeDate(newDate);
    };

    const monthName = currentDisplayDate.toLocaleString('default', { month: 'long' });
    const year = currentDisplayDate.getFullYear();

    const firstDayOfMonth = new Date(year, currentDisplayDate.getMonth(), 1).getDay();
    const lastDayOfMonth = new Date(year, currentDisplayDate.getMonth() + 1, 0).getDay();
    const daysInMonth = new Date(year, currentDisplayDate.getMonth() + 1, 0).getDate();

    const previousMonthDate = new Date(year, currentDisplayDate.getMonth() - 1, 0);

    const prevMonthDays = firstDayOfMonth === 0 ? 0 : firstDayOfMonth;
    const prevMonthLastDays: number[] = [];
    for (let i = prevMonthDays; i > 0; i--) {
        prevMonthLastDays.push(previousMonthDate.getDate() - i + 1);
    }
    prevMonthLastDays.reverse();

    const daysArray: number[] = [];
    for (let i = 1; i <= daysInMonth; i++) {
        daysArray.push(i);
    }

    const nextMonthDays = lastDayOfMonth === 6 ? 0 : 6 - lastDayOfMonth;
    const nextMonthFirstDays: number[] = [];
    for (let i = 1; i <= nextMonthDays; i++) {
        nextMonthFirstDays.push(i);
    }

    const calendarDays = [
        ...prevMonthLastDays.reverse(),
        ...daysArray,
        ...nextMonthFirstDays
    ];

    const chunkArray = (array: number[], chunkSize: number) => {
        const result: number[][] = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };

    const rows = chunkArray(calendarDays, 7);

    return (
        <div className="calendar-small">
            <Row className="small-month-navigation">
                <Col>
                    <Button className={"btn-arrow"} onClick={goToPreviousMonth}><ArrowRightBlueIcon class={"svg-flip-y"} /></Button>
                </Col>
                <Col className="small-month-name text-center" xs="7">
                    <span>{monthName} <span className="year">{year}</span></span>
                </Col>
                <Col>
                    <Button className={"btn-arrow"} onClick={goToNextMonth}><ArrowRightBlueIcon /></Button>
                </Col>
            </Row>

            <Row className="small-days-of-week">
                {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
                    <Col key={day} className="text-center">
                        <strong>{day}</strong>
                    </Col>
                ))}
            </Row>

            {rows.map((row, rowIndex) => {
                const today = new Date();
                const isFirstRow = rowIndex === 0;
                const isLastRow = rowIndex === rows.length - 1;

                let lastDayStatus: DeskStatus;
                let currentDayStatus: DeskStatus;
                let nextDayStatus: DeskStatus;

                return (
                    <Row key={rowIndex} className="small-calendar-grid">
                        {row.map((day, index) => {
                            let outsideActiveMonth = false;

                            // Determine if day is outside active month.
                            if (isFirstRow && day > 7) {
                                outsideActiveMonth = true;
                            }

                            if (isLastRow && day < row[0]) {
                                outsideActiveMonth = true;
                            }

                            // Assign calendar cell classes
                            let dayCellClasses = "";

                            if (outsideActiveMonth) {
                                dayCellClasses += " grey-day";
                            }

                            // Determine status for day.
                            if (bookingOptions !== undefined && desk !== undefined && !outsideActiveMonth) {

                                currentDisplayDate.setDate(day);

                                // Add 'today' class
                                if (currentDisplayDate.getUTCDate() === today.getUTCDate() &&
                                    currentDisplayDate.getUTCMonth() === today.getUTCMonth() &&
                                    currentDisplayDate.getUTCFullYear() === today.getUTCFullYear()) {
                                    dayCellClasses += "today";
                                }

                                // Get current day status if not carried over.
                                if (nextDayStatus) {
                                    currentDayStatus = nextDayStatus;
                                } else {
                                    const tempBookingOptions = { ...bookingOptions, date: currentDisplayDate.toISOString().split('T')[0] };

                                    currentDayStatus = getDeskStatus(tempBookingOptions, desk, false);
                                }

                                // Calculate next day availability.
                                if (!(isLastRow && row[index + 1] === 1)) {
                                    const nextDayDisplayDate = new Date(currentDisplayDate);
                                    nextDayDisplayDate.setDate(day + 1);

                                    const tempBookingOptions = { ...bookingOptions, date: nextDayDisplayDate.toISOString().split('T')[0] };

                                    nextDayStatus = getDeskStatus(tempBookingOptions, desk, false);
                                }

                                // Add classes.
                                if (currentDayStatus.available) {
                                    dayCellClasses += " available";
                                } else {
                                    dayCellClasses += " unavailable";
                                }

                                if (day === row[0] || day === 1 || (currentDayStatus.available !== lastDayStatus.available)) {
                                    dayCellClasses += " left-end";
                                }

                                if (day === row[row.length - 1] || (isLastRow && row[index + 1] === 1) || currentDayStatus.available !== nextDayStatus.available) {
                                    dayCellClasses += " right-end";
                                }

                                // Update last day.
                                lastDayStatus = currentDayStatus;
                            }

                            return (
                                <Col key={index} className="small-day-cell">
                                    <div className={dayCellClasses}>
                                        {day}
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                );
            })}
        </div>
    );
};

export default CalendarSmall;
