import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl, baseFetchFromServer } from "../AppSlice";
import { returnAction } from "../AppSlice";
import { setActionStatus } from "./ActionStatus";

const initialState = {
    allBookings: [],
    fetchAllBookingsRequestComplete: false,
    createBookingRequestComplete: false,
    deleteBookingRequestComplete: false,
};

export const fetchAllBookings = createAsyncThunk(`fetchAllBookings`,
    async (params, thunkApi) => {

        let endpoint = `${apiUrl}/portal/bookings?resources=desk&includePast=true&includeRecurring=true`;

        if (params.anyUser === true)
            endpoint = `${endpoint}&anyUser=true`;

        const response = await thunkApi.dispatch(baseFetchFromServer({
            input: endpoint,
            init: {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            }
        }));
        if (response.meta.requestStatus === 'rejected') {
            return thunkApi.rejectWithValue({ status: response.payload?.toString() ?? '' });
        }
        const resolvedResponse = response.payload;
        if (!(resolvedResponse.ok && resolvedResponse.text)) {
            return thunkApi.rejectWithValue({ status: resolvedResponse.status?.toString() ?? '' });
        }

        return resolvedResponse.text;
    }
);

export const createBooking = createAsyncThunk(`createBooking`,
    async (params, thunkApi) => {

        const response = await thunkApi.dispatch(baseFetchFromServer({
            input: `${apiUrl}/portal/bookings`,
            init: {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(params.body)
            }
        }));
        if (response.meta.requestStatus === 'rejected') {
            return thunkApi.rejectWithValue({ status: response.payload?.toString() ?? '' });
        }
        const resolvedResponse = response.payload;
        if (!(resolvedResponse.ok && resolvedResponse.text)) {
            return thunkApi.rejectWithValue({ status: resolvedResponse.status?.toString() ?? '' });
        }

        return resolvedResponse.text;
    }
);

export const updateBooking = createAsyncThunk(`updateBooking`,
    async (params, thunkApi) => {

        const response = await thunkApi.dispatch(baseFetchFromServer({
            input: `${apiUrl}/portal/bookings/${params.id}`,
            init: {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(params.body)
            }
        }));
        if (response.meta.requestStatus === 'rejected') {
            return thunkApi.rejectWithValue({ status: response.payload?.toString() ?? '' });
        }
        const resolvedResponse = response.payload;
        if (!(resolvedResponse.ok && resolvedResponse.text)) {
            return thunkApi.rejectWithValue({ status: resolvedResponse.status?.toString() ?? '' });
        }

        return resolvedResponse.text;
    }
);

export const deleteBookingById = createAsyncThunk(`deleteBookingById`,
    async (params, thunkApi) => {
        thunkApi.dispatch(
            setActionStatus({
                method: "DELETE",
                status: "pending",
                objectName: "Booking",
            })
        )
        const response = await thunkApi.dispatch(
            baseFetchFromServer({
                input: `${apiUrl}/portal/bookings/${params.id}`,
                init: {
                    method: "DELETE",
                    headers: new Headers({
                        "Content-Type": "application/json",
                    }),
                },
            })
        );

        return await returnAction(thunkApi, response)
    }
);


export const bookingsSlice = createSlice({
    name: "bookings",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchAllBookings.pending, (state, action) => {
            state.fetchAllBookingsRequestComplete = false;
        });
        builder.addCase(fetchAllBookings.fulfilled, (state, action) => {

            if (action.payload.length > 0) {
                const js = JSON.parse(action.payload);
                if (js.count > 0) {
                    let data = js.data;
                    Object.assign(state.allBookings, data);
                }
            }
            state.fetchAllBookingsRequestComplete = true;
        });
        builder.addCase(fetchAllBookings.rejected, (state, action) => {
            state.fetchAllBookingsRequestComplete = true;
        });
        builder.addCase(deleteBookingById.fulfilled, (state, action) => {
            state.deleteBookingRequestComplete = true;
            if (action.payload?.status === "success") {
                const i = state.allBookings.findIndex((booking) => booking.id === action.meta.arg);
                state.allBookings.splice(i, 1);
            }
        });
        builder.addCase(deleteBookingById.pending, (state, action) => {
            state.deleteBookingRequestComplete = false;
        });

        builder.addCase(deleteBookingById.rejected, (state, action) => {
            state.deleteBookingRequestComplete = false;
        });
        builder.addCase(createBooking.fulfilled, (state, action) => {
            state.deleteBookingRequestComplete = true;
        });
        builder.addCase(createBooking.pending, (state, action) => {
            state.deleteBookingRequestComplete = false;
        });

        builder.addCase(createBooking.rejected, (state, action) => {
            state.deleteBookingRequestComplete = false;
        });
    }
});

export const getAllBookings = (state) => state.bookings.allBookings;
export const getFetchAllBookingsRequestComplete = (state) => state.bookings.fetchAllBookingsRequestComplete;

export default bookingsSlice.reducer;
