import Groups from "../../components/Groups/Groups";
import DesksContainer from "../../components/DesksContainer/DesksContainer";
import "./Desks.scss";
import DeskBookingControls from "../../components/DeskBookingControls/DeskBookingControls";
import { useState, useEffect, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getCurrentDate, getCurrentTimeAndNextHour } from "../../utils/datetimeUtils";
import { fetchIsLicensed, getIsLicensed, getFetchIsLicensedComplete } from "../../store/licenceSlice";
import { fetchAllBookings } from "../../store/bookingsSlice";
import { fetchAllDesks } from "../../store/desksSlice";
import { getAuthorizationState } from "../../AppSlice";
import { useSelector } from "react-redux";
import { fetchProfiles, getProfiles, getFetchProfilesComplete } from "../../store/profilesSlice";
import { validateSettings } from "../../utils/bookingUtils";
import { RecurringEndCondition, ViewMode } from "../../types";

function Desks() {

    const dispatch = useAppDispatch();
    const [currentGroup, setCurrentGroup] = useState("");
    const TODAY = new Date();
    const RECURRING_DEFAULT_DAYS_IN_ADVANCE = 7; // Days from now to set the default End "On This Day" value in recurring options.

    const [defaultBookingSettings, setDefaultBookingSettings] = useState(
        {
            date: getCurrentDate(),
            startTime: getCurrentTimeAndNextHour().currentTime,
            endTime: getCurrentTimeAndNextHour().nextHourTime,
            allDay: false
        }
    );
    const defaultRecurringSettings =
    {
        recurringStartDate: getCurrentDate(),
        repeat: ViewMode.Day,
        frequency: 1,
        endConditionType: RecurringEndCondition.OnDay,
        endConditionValue: {
            onDay: new Date(TODAY.getTime() + RECURRING_DEFAULT_DAYS_IN_ADVANCE * 24 * 60 * 60 * 1000),
            afterXOccurrences: 1
        }
    };
    const isLicensed = useAppSelector(getIsLicensed);
    const isFetchIsLicensedComplete = useAppSelector(getFetchIsLicensedComplete);
    const isFetchIsProfilesComplete = useAppSelector(getFetchProfilesComplete);
    const authState = useSelector(getAuthorizationState);

    const allprofiles = useAppSelector(getProfiles);

    const [currentBookingSettings, setCurrentBookingSettings] = useState(defaultBookingSettings);
    const [currentRecurringBookingSettings, setCurrentRecurringBookingSettings] = useState(defaultRecurringSettings);
    const [isInitialised, setIsInitialised] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [resetFilter, setResetFilter] = useState(false);
    const [isRecurring, setIsRecurring] = useState(false);

    const PORTAL_ADMIN_ROLE = "portal.admin";

    const initialise = useCallback(() => {
        if (!isLicensed) {
            dispatch(fetchIsLicensed());
        }
        dispatch(fetchProfiles());
        setIsInitialised(true);
    }, [dispatch, isLicensed])

    useEffect(() => {
        if (isFetchIsProfilesComplete) {

            const defaultProfile = allprofiles.find(p => p.isDeskDefault);

            if (defaultProfile) {
                const newDefaultSettings = {
                    date: getCurrentDate(),
                    startTime: getCurrentTimeAndNextHour(defaultProfile.settings.defaultDurationMinutes).currentTime,
                    endTime: getCurrentTimeAndNextHour(defaultProfile.settings.defaultDurationMinutes).nextHourTime,
                    allDay: false
                };

                setDefaultBookingSettings(newDefaultSettings);
                setCurrentBookingSettings(newDefaultSettings);
            }
        }
    }, [isFetchIsProfilesComplete, allprofiles])

    useEffect(() => {
        if (!isInitialised) {
            initialise();
        }
    }, [isInitialised, initialise])

    useEffect(() => {
        if (!isFetchIsLicensedComplete) {
            dispatch(fetchIsLicensed());
        }
    }, [dispatch, isFetchIsLicensedComplete]);

    useEffect(() => {
        dispatch(fetchAllBookings({ anyUser: authState.roles?.includes(PORTAL_ADMIN_ROLE) }));
        dispatch(fetchAllDesks({ includeBookings: false }));
    }, [dispatch, authState.roles]);


    const handleChangeBookingControls = (option, value) => {

        let currentSettings = { ...currentBookingSettings };
        let currentRecurringSettings = { ...currentRecurringBookingSettings };


        switch (option) {
            case "date":
                currentSettings = ({ ...currentSettings, date: value });
                break;
            case "startTime":
                currentSettings = ({ ...currentSettings, startTime: value });
                break;
            case "endTime":
                currentSettings = ({ ...currentSettings, endTime: value });
                break;
            case "allDay":
                currentSettings = ({ ...currentSettings, allDay: value });
                break;
            case "recurringStartDate":
                currentRecurringSettings = ({ ...currentRecurringSettings, recurringStartDate: value });
                break;
            case "repeat":
                currentRecurringSettings = ({ ...currentRecurringSettings, repeat: value });
                break;
            case "frequency":
                currentRecurringSettings = ({ ...currentRecurringSettings, frequency: value });
                break;
            case "endConditionType":
                currentRecurringSettings = ({ ...currentRecurringSettings, endConditionType: value });
                break;
            case "endConditionValue":
                currentRecurringSettings = ({ ...currentRecurringSettings, endConditionValue: value });
                break;
            default:
                break;
        }

        setValidationErrors(validateSettings(currentSettings));
        setCurrentBookingSettings(currentSettings);
        setCurrentRecurringBookingSettings(currentRecurringSettings);
    };

    const resetFilterToDefaults = () => setResetFilter(true);

    const handleResetFilter = () => setResetFilter(false);

    return (<div className={"desks-page-wrapper main-wrapper"}>
        <div className={"page-header"}>
            <div className={"page-title main-page-title"}>
                <h1>Book A Desk</h1>
            </div>
        </div>
        <div className={"booking-controls-container"}>
            <DeskBookingControls
                setSettings={handleChangeBookingControls}
                resetFlag={resetFilter}
                onToggleRecurring={(toggle) => setIsRecurring(toggle)}
                onFilterReset={() => handleResetFilter()}
                defaultSettings={defaultBookingSettings}
                defaultRecurrenceSettings={defaultRecurringSettings}
            />
        </div>
        {validationErrors.map((err) => {
            return <div><span className="error-message">{err}</span></div>;
        })}
        <div className={"groups-and-desks-container"}>
            <Groups onGroupChange={(id) => setCurrentGroup(id)} />
            <DesksContainer
                groupId={currentGroup}
                bookingOptions={currentBookingSettings}
                recurringBookingOptions={isRecurring ? currentRecurringBookingSettings : null}
                onConfirmBooking={() => resetFilterToDefaults()}
                inactive={validationErrors.length > 0}
            />
        </div>
    </div>);
}

export default Desks;
