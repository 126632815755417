import { FC } from "react";
import "./SwitchButton.scss";

interface ToggleSwitchProps {
    changeHandler: () => void,
    isActive: boolean,
    disabled?: boolean,
}

const ToggleSwitch: FC<ToggleSwitchProps> = ({ changeHandler, isActive, disabled }) => (
    <span
        className="toggle-switch-container"
    >
        <input
            type="checkbox"
            checked={isActive}
            id="toggle-switch"
            onChange={changeHandler}
            hidden={true}
            disabled={disabled}
        />
        <label
            htmlFor="toggle-switch"
            className={`toggle-switch label ${disabled ? 'disabled' : ''}`}
        ></label>

    </span>
);

export default ToggleSwitch;