import { ViewMode } from "../types";

function getTimeFrequency(interval: ViewMode): string {
    switch (interval) {
        case ViewMode.Day:
            return 'daily';
        case ViewMode.Week:
            return 'weekly';
        case ViewMode.Month:
            return 'monthly';
        default:
            return "couldn't get interval";
    }
}

function getTimePlural(interval: ViewMode): string {
    switch (interval) {
        case ViewMode.Day:
            return 'days';
        case ViewMode.Week:
            return 'weeks';
        case ViewMode.Month:
            return 'months';
        default:
            return "couldn't get time plural";
    }
}

export {
    getTimeFrequency,
    getTimePlural
};