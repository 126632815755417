export enum ResourceType {
    Room,
    Desk
}

export enum ViewMode {
    Day = 1,
    Week = 2,
    Month = 3
}

export enum RecurringEndCondition {
    OnDay = "On This Day",
    AfterOccurrences = "After X Occurrences"
}

export interface Group {
    id: string;
    name: string;
    displayName: string;
    groupNamePath: string;
    itemCount: number;
    isDefault: boolean;
    active: boolean;
    parentActive?: boolean;
    parentGroupId?: string;
    childGroups?: Group[];
}

export interface Resource {
    id: string;
    type: ResourceType;
    name: string;
    groupNamePath: string;
    dateAvailable: string,
    timeAvailable: string,
    workHours: WorkHours | {},
    timeZone?: string | null,
    effectiveWorkHours: WorkHours | {},
    effectiveTimeZone?: string | null,
    issues?: string[],
    tags: string[];
}

export interface WorkHours {
    saturday: WorkHour;
    monday: WorkHour;
    tuesday: WorkHour;
    wednesday: WorkHour;
    thursday: WorkHour;
    friday: WorkHour;
    sunday: WorkHour;
    [key: string]: WorkHour;
}

export interface WorkHour {
    start: string,
    end: string;
}

export interface Desk extends Resource {
    bookings: Booking[];
    profile: DeskProfile;
}

export interface DeskProfile {
    id: string,
    name: string,
    description: string,
    groups: string[],
    isDeskDefault: boolean,
    settings: {
        create: boolean,
        cancel: boolean,
        extend: boolean,
        defaultDurationMinutes: number,
        maximumExtensionMinutes: number,
        maximumAdvancedBookingDays: number;
    };
}

export interface Booking {
    uid: string,
    resourceId: string,
    startTime: Date,
    endTime: Date,
    timeZone: string,
    allDay: boolean;
    resourceType: string;
    organiser: {
        name: string,
        email: string;
    };

    recurrence?: string[];
    isRecurring: boolean;
    isMasterOccurence: boolean;
    masterOccurenceUid?: string;
}

export interface Option {
    name: string,
    value: string;
}

export interface DeskStatus {
    available: boolean,
    outsideWorkingHours: boolean,
    unknown: boolean,
    bookedBy: string,
    date: string,
    startTime: string,
    endTime: string,
    bookedFrom?: string,
    bookedUntil?: string,
    errorMessage?: string,
    timeZone?: string | null;
}

export interface BookingSettings {
    date: string,
    startTime: string,
    endTime: string,
    allDay: boolean;
}

export interface RecurringBookingSettings {
    recurringStartDate: string,
    repeat: ViewMode,
    frequency: number,
    endConditionType: RecurringEndCondition;
    endConditionValue: {
        onDay: Date,
        afterXOccurrences: number;
    };
}

export interface TimeRange {
    currentTime: string;
    nextHourTime: string;
}