import { useEffect, useState } from 'react';
import './BookingCard.scss';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatDateToDDMMYYYY } from '../../utils/datetimeUtils';
// import Tags from '../Tags/Tags';
import OptionsDropdown from '../OptionsDropdown/OptionsDropdown';
import { useDispatch } from 'react-redux';
import { fetchDeskById } from '../../store/desksSlice';
import { ArrowBottomBlackIcon } from "../../assets/icons";
import { formatBookingHours } from '../../utils/bookingUtils';
import { RepeatingIconBlue } from '../../assets/icons';

const BookingCard = ({ booking, onOptionSelect }) => {

    const dispatch = useDispatch();
    const [resource, setResource] = useState();

    const isRecurring = booking.masterOccurenceUid;

    useEffect(() => {
        if (!resource) {
            dispatch(fetchDeskById({ id: booking.resourceId })).then((r) => {
                if (!r.error) {
                    const resource = JSON.parse(r.payload).data;
                    setResource(resource);
                }
            })
        }
    }, [dispatch, setResource, booking, resource])

    const BookingCardHeader = () => {
        const groups = resource ? resource?.groupNamePath ? resource?.groupNamePath.split(" ->") : undefined : undefined;

        const options = [
            { name: "Edit", value: "edit" },
            { name: "Cancel", value: "cancel" }
        ];

        const GroupPath = ({ flatGroupPath }) => {
            if (flatGroupPath === "" || flatGroupPath === undefined) {
                return <span>Ungrouped</span>;
            } else {
                const groups = flatGroupPath.split(" ->");
                return (
                    <>
                        {groups.map((group, index) => (
                            <>
                                <span>{group}</span>
                                {index < groups.length - 1 && (
                                    <>
                                        <br />
                                        <ArrowBottomBlackIcon className={"group-separator"} />
                                        <br />
                                    </>
                                )}
                            </>
                        ))}
                    </>
                );
            }
        };

        const flatGroupPath = resource?.groupNamePath;

        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
                <span className={"tooltip-header"}>{resource?.name}</span>
                <hr></hr>
                <GroupPath flatGroupPath={flatGroupPath} />
            </Tooltip>
        );

        return <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 50 }}
            overlay={renderTooltip}
        ><Card.Header>
                {resource ? <>
                    <div className='desk-number-group-container'>
                        <span className="desk-number">{resource?.name}</span>
                        {groups ?
                            <div className={"desk-group-hierarchy"}>
                                <span>{groups[0]}</span>
                                {groups.length > 1 ? <span>{groups[groups.length - 1]}</span> : <></>}
                            </div> : <></>}
                    </div>
                    {/* Hide options for recurring bookings temporarily. See RCM-6498. */}
                    <div className={"btn-options-container"} hidden={isRecurring}>
                        <OptionsDropdown options={options} onOptionSelect={(e) => onOptionSelect(e, booking.uid)} />
                    </div>
                </> : <></>}
            </Card.Header>
        </OverlayTrigger>;
    };

    const BookingCardBody = () => {

        const date = formatDateToDDMMYYYY(booking.startTime);

        if (booking.resourceType === "desk") {
            return <Card.Body>
                <div className="date-duration-issues-container">
                    <span className={"date"}>{date}</span>
                    <div className={"duration"}>{formatBookingHours(booking, true)}</div>
                    {resource?.issues && resource?.issues.length > 0 ?
                        <div className={"issues"}>
                            {resource.issues.map((issue) => <span key={issue} className={"issue"}><span className="error-icon" />{issue}</span>)}
                        </div>
                        : <></>}
                    <RepeatingIconBlue className={"repeating-icon"} hidden={!isRecurring} />
                </div>
                {/* Unhide to show tags */}
                {/* <hr className={"card-body-separator"} />
                {booking.resource.tags.length > 0 ?
                    <div className={"tag-container"}>
                        <Tags labels={booking.resource.tags} />
                    </div>
                    : <></>} */}
            </Card.Body>;
        } else
            return <></>;
    };

    if (booking !== undefined) {
        return <Card className={`booking-card desk`}>
            <BookingCardHeader />
            <hr className={"card-header-body-separator"} />
            <BookingCardBody />
        </Card>;
    }

};

export default BookingCard;