import { useCallback, useState } from 'react';
import './RecurringBookingControls.scss';
import { Col, Form, Row } from 'react-bootstrap';
import { ViewMode, RecurringEndCondition, RecurringBookingSettings } from '../../types';
import { getTimeFrequency, getTimePlural } from '../../utils/recurringUtils';
import { capitaliseFirstLetter } from '../../utils/stringUtils';

interface RecurringBookingControlsProps {
    setSettings: (option: string, value: any) => void,
    defaultRecurrenceSettings: RecurringBookingSettings,
}

const RecurringBookingControls: React.FC<RecurringBookingControlsProps> = (props: RecurringBookingControlsProps) => {

    const [startDate, setStartDate] = useState(props.defaultRecurrenceSettings.recurringStartDate);
    const [repeat, setRepeat] = useState(props.defaultRecurrenceSettings.repeat);
    const [frequency, setFrequency] = useState(props.defaultRecurrenceSettings.frequency);
    const [endConditionType, setEndConditionType] = useState(`${props.defaultRecurrenceSettings.endConditionType}`);
    const [endConditionValue, setEndConditionValue] = useState(props.defaultRecurrenceSettings.endConditionValue);

    const handleChange = useCallback((setting: string, value: any) => {
        props.setSettings(setting, value);
    }, [props]);

    const handleChangeStartDate = (newDate: string) => {
        setStartDate(newDate);
        handleChange("date", newDate);
    };

    const handleChangeRepeat = (freqValue: number) => {
        setRepeat(freqValue);
        handleChange("repeat", freqValue);
    };

    const handleChangeFrequency = (newFrequency: number) => {
        setFrequency(newFrequency);
        handleChange("frequency", newFrequency);
    };

    const handleChangeEndConditionType = (newEndCondition: string) => {
        setEndConditionType(newEndCondition);
        handleChange("endConditionType", newEndCondition);
    };

    const handleChangeEndConditionValue = (newValue: any) => {

        switch (endConditionType) {
            case RecurringEndCondition.AfterOccurrences:
                let newEndValueNumber = { ...endConditionValue, afterXOccurrences: newValue };
                setEndConditionValue(newEndValueNumber);
                handleChange("endConditionValue", newEndValueNumber);
                break;
            case RecurringEndCondition.OnDay:
                const newEndValueDate = { ...endConditionValue, onDay: newValue };
                setEndConditionValue(newEndValueDate);
                handleChange("endConditionValue", newEndValueDate);
                break;
            default:
                break;
        }
    };

    const getSelectableTimeFrequencies = () => {
        const timeMeasureValues = Object.values(ViewMode)
            .filter(value => typeof value === "number") as ViewMode[];

        return timeMeasureValues.map(value => <option value={value}>{capitaliseFirstLetter(getTimeFrequency(value))}</option>);
    };

    const getSelectableEndConditionType = () => {
        const endConditionValues = Object.values(RecurringEndCondition);

        return endConditionValues.map(value => <option value={value}>{value}</option>);
    };

    return (
        <div className={"recurring-controls-container"}>
            <Row>
                <Form.Label className={"start-date-label"} column>Start</Form.Label>
                <Col className={"start-date-col"}>
                    <Form.Control
                        type="date"
                        placeholder="Date"
                        onChange={e => handleChangeStartDate(e.target.value)}
                        value={startDate}
                    />
                </Col>
                <Form.Label className={"repeate-label"} column>Repeat</Form.Label>
                <Col className={"repeat-col"} lg={2}>
                    <Form.Select aria-label="Repeat Frequency" onChange={(e) => handleChangeRepeat(+e.target.value)}>
                        {getSelectableTimeFrequencies()}
                    </Form.Select>
                </Col>
                <Form.Label className={"frequency-fixed-label"} column>Every</Form.Label>
                <Col className={"frequency-col"} lg={1}>
                    <Form.Control
                        type="number"
                        placeholder="1"
                        min={1}
                        onChange={e => handleChangeFrequency(+e.target.value)}
                        value={frequency}
                    />
                </Col>
                <Form.Label className={"frequency-dynamic-label"} column>{getTimePlural(repeat)}</Form.Label>
                <Form.Label className={"end-type-label"} column>End</Form.Label>
                <Col className={"end-type-col"} lg={2}>
                    <Form.Select aria-label="End Type Select" onChange={(e) => handleChangeEndConditionType(e.target.value)}>
                        {getSelectableEndConditionType()}
                    </Form.Select>
                </Col>
                <Col className={"end-value-col"}>
                    <Form.Control
                        type="date"
                        placeholder="Date"
                        onChange={(e) => handleChangeEndConditionValue(new Date(e.target.value))}
                        value={endConditionValue.onDay.toISOString().split("T")[0]}
                        min={props.defaultRecurrenceSettings.recurringStartDate}
                        hidden={endConditionType !== RecurringEndCondition.OnDay}
                    />
                    <Form.Control
                        className='input-number'
                        type="number"
                        placeholder="1"
                        min={1}
                        onChange={e => handleChangeEndConditionValue(+e.target.value)}
                        value={endConditionValue.afterXOccurrences}
                        hidden={endConditionType !== RecurringEndCondition.AfterOccurrences}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default RecurringBookingControls;