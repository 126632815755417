import { BookingSettings, DeskProfile, Resource, WorkHours, WorkHour, Booking, RecurringBookingSettings, RecurringEndCondition } from "../types";
import { isTimeBefore, getDateTimeFromTimeZone, getDuration, formatTimeRange } from "./datetimeUtils";
import { getTimeFrequency } from "./recurringUtils";

// Check options and return array of error messages.
function validateSettings(settings: BookingSettings, profile?: DeskProfile): string[] {

    let errorMessages = [];

    if (settings) {
        // Check profile settings
        if (profile) {
            if (!profile.settings.extend)
                errorMessages.push("This booking cannot be edited as per it's resource's profile settings.");
        }

        if (!settings.allDay) {
            // If not all day...
            // Check if settings aren't empty.
            if (settings.date === "" || (settings.startTime === "" || settings.endTime === ""))
                errorMessages.push("Please fill out all options.");

            // Check if date is in the past.
            if (new Date(`${settings.date}T${settings.startTime}`) < new Date())
                errorMessages.push("You cannot book resources in the past.");

            // Check if start time is after end time.
            if (isTimeBefore(settings.endTime, settings.startTime))
                errorMessages.push("End time must be after start time.");

            // Check if start time and end time are the same.
            if (settings.endTime === settings.startTime)
                errorMessages.push("Start time must be different from end time.");
        } else {
            // If all day...
            // Check if date isn't empty.
            if (settings.date === "")
                errorMessages.push("Please select a date.");

            // Check if date is in the past.
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            if (new Date(`${settings.date}T00:00`) < today)
                errorMessages.push("You cannot book resources in the past.");
        }
    }

    return errorMessages;
}

// Returns all work hours for a resource
function getWorkHours(resource: Resource): Partial<WorkHours> {
    const workingHours: Partial<WorkHours> = resource.effectiveWorkHours && Object.keys(resource.effectiveWorkHours).length > 0
        ? resource.effectiveWorkHours
        : resource.workHours;

    return workingHours;
}

function getTimeZone(resource: Resource): string | null | undefined {
    return resource.effectiveTimeZone ?? resource.timeZone;
}

// Convert work hours in HH:mm format to ISO date/time with specified date and time-zone origin. Null time zone = local
function getWorkHoursIso(
    dateString: string,
    workHours?: WorkHour | null,
    timeZoneFrom?: string | null): WorkHour | null {
    if (!workHours)
        return null;

    // Working hours for this date and time zone
    const deskWorkHoursStart = getDateTimeFromTimeZone(dateString, workHours.start, timeZoneFrom);
    const deskWorkHoursEnd = getDateTimeFromTimeZone(dateString, workHours.end, timeZoneFrom);

    return {
        start: deskWorkHoursStart,
        end: deskWorkHoursEnd
    };
}

function formatBookingHours(booking: Booking, withDuration: boolean = false): string {

    if (booking.allDay) {
        return 'All Day';
    }

    const startTime = new Date(booking.startTime);
    const endTime = new Date(booking.endTime);

    const durationStr = withDuration ? ` (${getDuration(startTime, endTime)} hours)` : "";

    return `${formatTimeRange(startTime, endTime)}${durationStr}`;
}

// Generate RFC-5545 compatible string for recurrence rules. 
function getRecurrenceRules(recurringOptions: RecurringBookingSettings): string {
    let rules: string[] = [];

    // Add FREQ rule.
    const freq = getTimeFrequency(recurringOptions.repeat).toUpperCase();

    if (!freq) {
        throw new Error(`Invalid repeat value: ${recurringOptions.repeat}`);
    }
    rules.push(`FREQ=${freq}`);

    // Add INTERVAL rule.
    if (recurringOptions.frequency > 1) {
        rules.push(`INTERVAL=${recurringOptions.frequency}`);
    }

    // Add UNTIL or COUNT based on end condition type.
    if (recurringOptions.endConditionType === RecurringEndCondition.OnDay) {
        // Convert endConditionValue to an RFC-5545 UTC date string.
        const endDate = recurringOptions.endConditionValue.onDay;

        // Set the time to 23:59:59 (end of the day) in UTC. This is to ensure UNTIL is always inclusive.
        const inclusiveEndDate = new Date(endDate);
        inclusiveEndDate.setUTCHours(23, 59, 59, 999);

        const until = inclusiveEndDate.toISOString().replace(/[-:]/g, "").split(".")[0] + "Z";
        rules.push(`UNTIL=${until}`);
    } else if (recurringOptions.endConditionType === RecurringEndCondition.AfterOccurrences) {
        rules.push(`COUNT=${+recurringOptions.endConditionValue.afterXOccurrences}`);
    }

    // Combine rules into semicolon-separated string.
    return rules.join(";");
}

export { validateSettings, getWorkHours, getTimeZone, getWorkHoursIso, formatBookingHours, getRecurrenceRules };